//en.js
const hostname = window.location.hostname;
const en = {
    "baseurl": hostname == "exhibitor.sialchina.com" ? "" : "/en",
    "vst_baseurl": "/en",
    "lang": "en",
    "head_title": "Exhibitor member centersial china - sial china, sial china 2024, sial shanghai 2024 ",
    "sh_expo_time": "2024.05.28-30 ",
    "sz_expo_time": "2024.09.02-04 ",
    "meta": {
        "首页": "Home ",
        "会员信息": "Member Information ",
        "基本信息": "Basic Information ",
        "联系人": "Contacts ",
        "修改密码": "Change password ",
        "参展记录": "Exhibition Record ",
        "上海展": "SIAL Shanghai ",
        "深圳展": "SIAL Shenzhen ",
        "展位详情": "Booth details ",
        "展位预定": "Booth booking ",
        "展示名录": "Display list ",
        "参展管理": "Exhibition Management ",
        "团队成员": "Team member ",
        "团队成员信息": "Team member information ",
        "会刊信息": "Catalogue Information ",
        "企业logo": "Enterprise Logo ",
        "展品信息": "Products ",
        "展会相关": "Marketing Service ",
        "宣传服务": "Press Release ",
        "新闻详情": "News details ",
        "联名广告": "Media Kit ",
        "商贸配对": "Matchmaking ",
        "预约观众": "Visitors Appointment ",
        "查看检索历史": "Visitors Searched ",
        "我的收藏": "My collection ",
        "预约管理": "Appointment Management ",
        "收到的约见": "Appointment Received ",
        "发起的约见": "Appointment Launched ",
        "我的日程": "My Schedule ",
        "食品产业峰会": "Industry Summit ",
        "线下峰会": "Summit Tickets ",
        "峰会直播": "Live Streaming ",
        "峰会大咖": "Highlight Speech ",
        "主场搭建": "Stand Construction ",
        "客户邀请": "Invite Customers ",
        "电子邀请": "E-mail invitation ",
        "海报邀请": "Customized E-invitation ",
        "下载中心": "Download ",
        "赞助意向": "Sponsorship intention ",
        "签证邀请函": "Invitation Letter ",
        "信息订阅": "Mail subscription ",
        "SIAL创新大赛": "SIAL Innovation ",
        "上海创新大赛": "SIAL Shanghai Innovation ",
        "深圳创新大赛": "SIAL Shenzhen Innovation ",
        "参展商证件": "Exhibitor Badge ",
        "退出": "Exit ",
        "专属广告": "CUSTOMISED BANNERS ",
        "通用横幅广告": "GENERIC BANNERS ",
        "展会标志": "EXHIBITION LOGOS ",
        "更多广告机会": "更多广告机会 ",
        "查找观众": "Search Visitors ",
        "查看我的收藏": "My collection ",
        "臻牛奖": "SIAL BEST STEAK AWARDS ",
        "电子会刊": "Online Catalogue"
    },
    "footer": {
        "copyright": `Copyright © ${new Date().getFullYear()} Comexposium-SIAL Exhibition Co., Ltd. All rights Reserved `,
        "技术提供": "Support By: FlexEvent ",
        "北京爱博西雅展览有限公司": "Comexposium-SIAL Exhibition Co., Ltd ",
        "上海展会主办单位": "SIAL Shanghai Organizer ",
        "深圳展会主办单位": "SIAL Shenzhen Organizer ",
        "中国商业联合会": "中国商业联合会 ",
        "总部地址": " ",
        "联系电话": " ",
        "address": "Suite 1401, Prime Tower, No.22 Chaoyangmenwai Dajie, Beijing ,China ",
        "联系方式": "联系方式 ",
        "参展咨询": "Booth Enquiry ",
        "参观咨询": "Badge Enquiry ",
        "媒体合作": "Media Partner ",
        "论坛会议及活动": "Industry Summit ",
        "微信订阅号": "SIAL Shanghai ",
        "微信服务号": "微信服务号 ",
        "SIAL在线": "SIAL在线 ",
        "小红书": "小红书 ",
        "stipulation_tip": "This exhibition is a professional food and beverage trade show, open exclusively to industry professionals. Individuals under 18 and non-professionals over 70 are restricted to enter the venue. ",
        "010-8572 8461": "+86 (10) 6588 6794 ",
    },
    "返回官网": "Website ",
    "date format": "Y.M.D ",
    "exhibitor title": "Exhibitor Member Center ",
    "SIAL in China": "SIAL in China ",
    "sial title": "SIAL in China ",
    "填写公司信息": "Company information ",
    "注册成功": "Registration Completed ",
    "input account": "Please enter your user name ",
    "input password": "Please enter password ",
    "remember password": "Remember the password ",
    "login": "Login ",
    "reg": "Register ",
    "tip": "Tips ",
    "The login user name and password shall be provided by the organizer.": "The login user name and password shall be provided by the organizer. ",
    "If you have any questions, please contact your sales.": "If you have any questions, please contact your sales. ",
    "shanghai introduction": " ",
    "shenzhen introduction": "SIAL Shenzhen will be held at the Shenzhen Convention & Exhibition Center from September 2-4, 2024. The exhibition area will cover 60,000 square meters, expecting to attract over 1,500 exhibitors and over 67,000 professionals from around the world. ",
    "official website": "Go to homepage ",
    "browser recommended": "In order to facilitate your reading, it is recommended to use a browser: Microsoft Edge, Google 60, Firefox 60 and above to access the system ",
    "welcome": "Welcome to ",
    "Guide": "Guide ",
    "You can complete your company's exhibition information through the following guidelines": "You can complete your company's exhibition information through the following guidelines ",
    "Step 1": "Step 1 ",
    "Catalogue": "Catalogue ",
    "Step 2": "Step 2 ",
    "Products": "Exhibitor Product ",
    "add product": "Add ",
    "my products": "My product",
    "uploaded": "Total ",
    "invited": "Invited ",
    "Step 3": "Step 3 ",
    "Invite Customers": "Invite Customers ",
    "add invite": "Add ",
    "Step 4": "Step 4 ",
    "Exhibitor Badge": "Exhibitor Badge ",
    "add badge": "Add ",
    "company": "Company ",
    "edit info": "Edit ",
    "venus": "Hall ",
    "馆": " ",
    "号": " ",
    "Booth No": "Booth No. ",
    "Booth Type": "Booth Type ",
    "hall": "Hall ",
    "aisle": "Aisle ",
    "Square": "Booth Space ",
    "No booth has been allocated": "No booth has been allocated ",
    "no data": "No Data ",
    "sial exhibition arrangement": "SIAL in China 2024 Annual Exhibition Schedule ",
    "ExhibitorHandbook": "https://imgs.sialchina.cn/download/20240729/1250f5b9b37f7f99533338060367215966a709cd94d09.pdf",
    "ExhibitorHandbookFilename": "SIAL Shenzhen 2024 Manual.pdf",
    "Exhibition hall plan": "Exhibition Hall Plan",
    "Exhibitor Manual": "Exhibitor Manual",
    "booth booking": "Booth Booking ",
    "please input": "Please enter ",
    "please select": "Please select ",
    "please upload": "Please upload ",
    "your": "your ",
    "a valid": "a valid ",
    "change info, please contact sial": "To change contact information, please find the organizer of SIAL in China ",
    "password format": "Enter an 8-12 character password that contains digits, uppercase and lowercase letters, or symbols. ",
    "two password is defferent": "The login password is different from the confirm password ",
    "change password success": "Your password has been changed successfully ",
    "tips": "Tips ",
    "notice": "Tips ",
    "add": "Add ",
    "sort": "Sort ",
    "info": {
        "company info": "Company information ",
        "账号名称": "Account Company ",
        "contacts info": "Contacts information ",
        "catalogue name": "Company Name ",
        "company": "Company Name ",
        "short langCn": " (CN) ",
        "short langEn": " (EN) ",
        "long langEn": " (in English) ",
        "long langEn2": " in English ",
        "long langCn": " in Chinese only ",
        "select langCn": "Chinese ",
        "select langEn": "English ",
        "country": "Country/Region ",
        "china": "China ",
        "area": "Province/City ",
        "province": "Province ",
        "city": "City ",
        "address": "Company Address ",
        "truename": "Name ",
        "mobile": "Mobile ",
        "email": "Email ",
        "company email": "Email ",
        "password": "Password ",
        "old password": "Old Password ",
        "new password": "New Password ",
        "confirm password": "Confirm New Password ",
        "Business Scope": "Business Scope ",
        "website": "Website ",
        "Company Introduction": "Company Introduction ",
        "Products Categories": "Products Categories ",
        "Theme Exhibitions": "Theme Exhibitions ",
        "Brand information": " Brand Information ",
        "Brand Name": "Brand Name ",
        "Brand Website": "Brand Website ",
        "Brand Description": "Brand Description ",
        "Current exportation": "Current exportation ",
        "Find Agents": "Find Agents ",
        "Your Target Markets": "Your Target Markets ",
        "Recommanded Trip": "Recommended Trip ",
        "product infomation": "Product Infomation ",
        "product name": "Product Name ",
        "is new": "Is it a new product ",
        "is extend": "Do you need the organizer to promote this product? ",
        "lightspot": "Features ",
        "place of origin": "Place of origin ",
        "cover image": "Cover image ",
        "product image": "Product picture ",
        "video link": "Video link ",
        "news link": "News Link ",
        "product introduction": "Product Description ",
        "Storage Conditions": "Storage Conditions ",
        "product category": "product category ",
        "select exhibition": "Please select an exhibition ",
        "yes": "Yes ",
        "no": "No ",
        "news title": "News Title ",
        "desc": "Description ",
        "news content": "News Content ",
        "submit": "Submit ",
        "amost 10": "10 at maximum ",
        "amost 4": "4 at maximum ",
        "amost": "at maximum ",
        "account": "Login Name ",
        "submit success": "Submitted successfully ",
        "update success": "Successfully updated",
        "subscribe lang": "Language ",
        "subscribe type": "Type ",
        "Products of Interest": "Your Products of Interest ",
        "展会快讯": "Exhibition News ",
        "行业资讯": "Industry News ",
        "新品推荐": "New Products ",
        "活动推介": "Summits & Events ",
        "展商介绍": "Exhibitor ",
        "exhibit image": "Product Picture ",
        "exhibit introduction": "Product Introduction ",
        "Business type": "Business type ",
        "观众公司名称": "Company Name ",
        "观众姓名": "Name ",
        "观众职位": "Position ",
        "业务范围": "Business Scope ",
        "感兴趣展品": "Interested Exhibits ",
        "预约时间": "Appointment ",
        "备注": "Note ",
        "上午": "am ",
        "下午": "pm ",
        "展区": "Exhibition area ",
        "受邀公司名称": "Visitor Company ",
        "受邀人姓名": "Name ",
        "买家姓名": "Visitor Name ",
        "联系人": "Contacts ",
        "登录账号": "Account ",
        "登录密码": "Password ",
        "订阅展会": "Exhibition ",
        "兴趣主题": "Themes of interest ",
    },
    "example": "Example ",
    "status": "Status ",
    "all": "All ",
    "session": "Session ",
    "exhibition": "Exhibition ",
    "exhibition name": "Exhibition Name ",
    "operate": "Operation ",
    "booth detail": "Booth details ",
    "Not exhibited": "Not exhibited ",
    "Already exhibited": "Already exhibited ",
    "back": "Back ",
    "delete": "Delete ",
    "publish time": "Time ",
    "create time": "Date ",
    "time": "Time ",
    "edit": "Edit ",
    "view": "View ",
    "browse": "View ",
    "search": "Search ",
    "introduction": "View ",
    "are your sure delete": "Are your sure delete? ",
    "Successfully deleted": "Successfully deleted! ",
    "check at least one": "You need to check at least one ",
    "Permission node": "Permission node ",
    "confirm": "Confirm ",
    "cancel": "Cancel ",
    "在线展商名录": "Online Exhibitor list ",
    "我的展商名录": "My Exhibitor List ",
    "以下信息将用于展商名录展示，点击查看": "The following information will be used for Online exhibitor list, click to view ",
    "format": "Format ",
    "size": "Size ",
    "Recommended size": "Recommended size ",
    "image size width": "pixels ",
    "drag file": "Drag the file here or click Upload ",
    "file upload": "Upload ",
    "log in first": "Please log in first ",
    "Log back in": "Log back in ",
    "请求超时，请刷新后重试": "The request timed out. Please refresh and try again ",
    "参数有误，请刷新后重试": "Parameter error, please refresh and try again ",
    "start time": "Start Time ",
    "end time": "End Time ",
    "to": "To ",
    "type": "Type ",
    "download": "Download ",
    "more": "More ",
    "image": "Image ",
    "activitive intro": "More ",
    "activitive address": "Address ",
    "Please select year, month": "Please select year, month ",
    "Copy": "Copy ",
    "Method One": "Method One ",
    "Method Two": "Method Two ",
    "Language": "Language ",
    "Language vension": "Language ",
    "请输入好友信息": "Please enter your friends information. ",
    "展商单次邀请观众邮箱数量不能超过50个。": "Please enter NO more than 50 e-mail address each time. ",
    "邀请多个观众时，请每行填写一个观众邮箱。": "邀请多个观众时，请每行填写一个观众邮箱。 ",
    "推荐好友成功！": "Invite friends successfully! ",
    "复制成功，赶快分享给好友吧": "Copy success, quickly share to friends ",
    "One mailbox per line": "One mailbox per line ",
    "E-mail invitation Template": "E-mail invitation Template ",
    "For more information about SIAL in China, please fill in the information below to subscribe": "For more information about SIAL in China, please fill in the information below to subscribe! ",
    "template": "template ",
    "select the template and get customized invitation letter": "Please select the template you like and get your customized invitation letter ",
    "Generate invitation": "Generate invitation ",
    "您可通过微信扫码保存至手机或直接下载邀请函发送给客户！返回可重新生成邀请函。": "You can scan the QR code to save the image to your mobile phone or download the invitation letter to send it to the customer! Back to regenerate the invitation. ",
    "back to edit": "Back to Edit ",
    "download invitation": "Download ",
    "请填写完信息": "Please complete the information  ",
    "生成横幅广告": "GENERATE BANNERS ",
    "下载所有广告": "Download All ",
    "Not interested": "Not interested ",
    "Cancel Collection": "Cancel Collection ",
    "Collection": "Collection",
    "Appointment": "Appointment ",
    "Appointmented": "Appointmented ",
    "Cancel Collection success": "Cancel success ",
    "Collection success": "Collection success ",
    "You have reserved this visitor, please wait patiently for the reply": "You have reserved this visitor, please wait patiently for the reply. ",
    "Please select an appointment time": "Please select an appointment time ",
    "您的预约信息已经提交，请耐心等待回复": "You have submitted successfully ",
    "接受": "Accept ",
    "拒绝": "Refuse ",
    "确定拒绝约见吗？": "Are you sure you want to delete the selected meeting reservation information? ",
    "成功拒绝": "Successful rejection ",
    "同意约见": "Successful ",
    "新增展品信息成功": "Successful ",
    "add news": "Add News ",
    "以下联系人信息仅作联系使用（例如：接收系统通知），将不会对外展示": "The following contact information is for communication purposes only (e.g., to receive system notifications) and will not be displayed publicly. ",
    "Product sorting tips": "You can use the 'Sort' function to arrange the exhibits. It will determine the display order of your products in the 'Exhibitor List - Exhibitor Details’ section. The smaller the numerical value, the higher the priority in sorting. ",
    "teamstaff tips": "If you need assistance from your colleagues in managing your company's exhibition information, such as catalogs, exhibits, news, etc., please follow the system prompts to create a team administrator login account and select the appropriate permission group. ",
    "Click on each banner to preview them": "Click on each banner to preview them, obtain the integration code or save them to your computer. ",
    "You are already an member": "You are already an member for SIAL in China ",
    "Enter Exhibitor Member Center": "Enter Exhibitor Member Center ",
    "use account to log in member center to view information": "Please use the following account to log in to the Exhibitor Member Center to view the relevant information of SIAL in China. ",
    "Opening soon": "Opening soon! ",
    "请重新登录": "Please log in. ",
    "username": "Username ",
    "请先完成会刊信息": "Please complete the catalogue information! ",
    "请先上传展品信息": "Please complete the products! ",
    "只允许输入文字内容，不可上传图片": "Only text content is allowed, no pictures can be uploaded ",
    "invite_recommend_tip": "Use the following functions to invite customers to register in advance and quickly enter the exhibition on the spot to avoid crowding and queuing.",
    "exhibit room": "My product",
    "exhibit room tips": "If you need to display your product in the product list, please turn on the display status of the product under the corresponding exhibition (SIAL Shanghai / SIAL Shenzhen).",
}
 
export default en